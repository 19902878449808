.banners-provider-slider {
  .banners-provider-slide {
    height: fit-content !important;
    margin-top: 0px !important;
    margin-bottom: 10px !important;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.4) !important;
    border-radius: 5px;
    
    .promotion-img-provider {
      border-radius: 5px;
    }
  }
}

