div.swiper-pagination.swiper-pagination-bullets {
  bottom: -1px;
  height: 30px;
  .swiper-pagination-bullet {
    background: #747474;
    &.swiper-pagination-bullet-active {
      background: var(--bullet-background-active);
    }
  }
}

.product-slider-promotion .swiper-container{
  overflow: visible !important;
}

@media only screen and (max-width: 420px) {
  .product-slider-promotion {
    ion-slides {
      max-height: 200px;
    }
  }
}
@media only screen and (max-width: 360px) {
  .has-responsive-father{
    width: 159px;
  }
}

@media only screen and (max-width: 350px) {
  .has-responsive-father{
    width: 149px;
  }
}

@media only screen and (max-width: 320px) {
  .has-responsive-father{
    width: 136px;
  }
}

@media only screen and (max-width: 300px) {
  .has-responsive-father{
    width: 132px;
  }
}
