.home-provider-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    ion-icon,
    h3 {
      color: var(--color-blue);
    }

    ion-icon {
      cursor: pointer;
      font-size: 2rem;
      font-weight: 600;
    }

    h3 {
      font-weight: 700;
      margin-top: 0.8rem;
      font-size: 1.3rem;
    }
  }
}

.mobile {
  padding: 40px 10px 20px 10px;
  height: 98px;
}

.web {
  padding: 20px 10px 20px 10px;
  height: 79px;
}
