.location-search-input {
  width: 100%;
  padding: 10px;
  position: relative;
  z-index: 4;
  border-radius: 16px;
  border: 1px solid var(--color-blue);
  outline: 1px solid var(--color-blue);

  &:active {
    border: 1px solid var(--color-blue);
  }

  &:focus {
    border: 1px solid var(--color-blue);
  }
}
