@import 'base.scss';
@import 'fonts.scss';
@import 'text';
@import 'buttons';
@import 'forms.scss';
@import 'general.scss';

iframe {
  display: none;
}

body {
  margin: 0;
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --default-background-color: #{$default-background-color};
  --background-color-blue: #{$background-color-blue};
  --primary-background-color: #{$primary-background-color};
  --primary-color: #{$primary-color};
  --inactive-color: #{$inactive-color};
  --secondary-color: #{$error-color};
  --text-label-color: #{$text-label-color};
  --text-primary-color: #{$text-primary-color};
  --text-disabled-color: #{$text-disabled-color};
  --emphasis-color: #{$emphasis-color};
  --emphasis-background-color: #{$emphasis-background-color};
  --separator-color: #{$separator-color};
  --heder-productSlider: #{$heder-productSlider};
  --text-title-gray-6: #{$text-title-gray-6};
  --primary-color-celeste: #{$primary-color-celeste};
  --new-primary-color-celeste: #{$new-primary-color-celeste};
  --button-footer: #{$button-footer};
  --text-title-gray-9: #{$text-title-gray-9};
  --text-title-gray-3: #{$text-title-gray-3};
  --background-green: #{$background-green};
  --background-green-primary: #{$background-green-primary};
  --background-app: #{$background-app};
  --color-blue : #{$color-blue};
  --white: #{$white};
  --form-gray: #{$form-gray};
  --blue-active-filter :#{$blue-active-filter};
  --normal-text: #{$normal-text};
  --new-dark-blue: #{$new-dark-blue};
  --ion-font-family: 'Nunito';
  --ion-default-font: "Nunito","Roboto","Helvetica Neue",sans-serif;
}   