@import '../../assets/scss/base';

.all-category {
  background-color: var(--background-color-blue);
  .category-content-container {
    background-color: var(--background-color-blue);
    height: 100%;
  }
  .container-title-img{
    background-color: var(--background-color-blue);
    overflow: auto;
    width: 100%;
    .category-title-wrapper {
      padding: 10px 30px;
      .category-back-icon {
        margin-right: 20px;
      }
      .category-page-title {
        margin-bottom: 0px;
        display: inline-block;
      }
    }
  }
}

.container-provider-category{
  @include container;
  .card-provider-category{
    width: 33.3%;
    display: inline-block;
    vertical-align: middle;
    padding: 5px;
    .img-provider-category{
      padding-bottom: 100%;
      background-size: cover;
      border: 1px solid darken($background-app, 7%);
      border-radius: 5px;
    }
  }

  @media (min-width: $media_sm){
    .card-provider-category{
      width: 20%;
    }
  }
}

@media only screen and (min-width: 1024px) {  
  .all-category{
    .container-title-img{
      padding-top: 33px;
      .category-page-title{ 
        width: 100%;
        max-width: 975px;
    }
  }
}
}