.suggested-product-card {
  height: auto;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 3px 10px rgba(30, 55, 153, 0.1);

  & > .content-image {
    display: flex;
    justify-content: center;
    padding-top: 0.5rem;
    & > img {
      height: 7rem;

      @media only screen and (max-width: 992px) {
        max-height: 5rem;
      }
    }
  }

  & > .content-info {
    padding: 0.5rem 0.4rem 1.1rem 0.4rem;

    & > h5 {
      color: var(--color-blue);
      font-size: 1.2rem;
      font-weight: 600;
    }

    & > .span-info {
      display: block;
      font-size: 0.75rem;
      color: #333333;

      &--margin {
        margin-top: -0.5rem;
      }
    }

    & > .buttons {
      position: relative;
      top: 8px;
      height: fit-content;
      width: fit-content !important;
      margin: 0 auto;

      ion-col {
        text-align: center;
      }

      .button {
        margin: 0px;
        padding: 0px;
        width: fit-content;
        height: fit-content;
        border-color: #d9d8db;
        background-color: var(--color-blue);
        font-size: 12px;
        opacity: 1;

        &__active {
          background-color: var(--color-blue);
        }

        &__disabled {
          background-color: #999;
        }

        .icon {
          margin: 0;
          width: 25px;
          height: 25px;
          color: #ffff;
        }
      }

      .btn-left {
        border-radius: 100%;
      }

      .btn-right {
        border-radius: 100%;
      }

      .value-cell {
        color: var(--color-blue);
        display: inline-block;
        width: 1em;
        vertical-align: middle;
        font-size: 16px;
        min-width: 3em;
        font-weight: bold;
        text-align: center;
        --padding-top: 3px;
        --padding-end: 0;
        --padding-bottom: 0px;
        --padding-start: 0;
      }
      .inputs-background {
        position: absolute;
        width: 80%;
        height: 100%;
        margin: 0px auto;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: var(--white);
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      }
    }
  }
}
