.number-updown-wrapper{
  width: fit-content !important;
  margin-top: -12px;

  .number-updown {
    position: relative;
    top: 8px;
    height: fit-content;
    ion-col {
      text-align: center;
    }
    .button {
      margin: 0px;
      padding: 0px;
      width: fit-content;
      height: fit-content;
      border-color: #d9d8db;
      background-color: var(--color-blue);
      font-size: 12px;
      opacity: 1;
      .icon {
        margin: 0;
        width: 25px;
        height: 25px;
        color: #ffff;
      }
    }
    .btn-left{
      border-radius: 100%;
    }
    .btn-right{
      border-radius: 100%;
    }
    .btn-add-disabled {
      background-color: var(--inactive-color) !important;
    }
    .value-cell {
      color: var(--color-blue);
      display: inline-block;
      width: 1em;
      vertical-align: middle;
      font-size: 16px;
      min-width: 3em;
      font-weight: bold;
      text-align: center;
      --padding-top: 3px;
      --padding-end: 0;
      --padding-bottom: 0px;
      --padding-start: 0;
    }
    .inputs-background {
      position: absolute;
      width: 80%;
      height: 100%;
      margin: 0px auto;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: var(--white);
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    }
  }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
}
input[type=number] {
  -moz-appearance:textfield;
}
