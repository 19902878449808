.product-slider {
  border-bottom: 1px solid #f1f1f1;
}

.banner-slide-home {
  height: 100% !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.4) !important;
  border-radius: 5px;
  
  .promotion-img-home {
    border-radius: 5px;
  }
  &.banner-type-hashtags {
    background-color: #FFF;
    ion-title {
      color: #1E3799;
      font-weight: 800;
      line-height: 17px;
      font-size: 17px;
      margin: 16px;
    }
  }
}


ion-modal.code-html-modal-dialog {
  .modal-wrapper {
    background: transparent;
    div.ion-page {
      left: 5%;
      right: 5%;
      top: 8%;
      overflow: visible;

      .content {
        background: #fff;
        overflow: scroll;
        border-radius: 8px;
        > .header {
          display: flex;
          justify-content: flex-end;
          padding: 4px 4px 4px 0px;
          font-size: 1.8em;
          color: #142242;
          opacity: 0.5;
        }

        > .body {
          display: flex;
          flex-direction: row;
          padding: 0 18px;
          > div {
            &:nth-child(1) {
              height: 30em;
              width: 100%;
              display: flex;
              align-items: flex-start;
              justify-content: center;
              > .picture {
                background-size: auto 90%;
                background-position: center;
                background-repeat: no-repeat;
                height: 8em;
                width: 8em;
                border: 1px solid #e8e8e8;
                border-radius: 0.25rem;
              }
            }
            &:nth-child(2) {
              width: 100%;
              > .title {
                color: var(--text-primary-color);
                font-weight: 700;
                font-size: 1.15em;
                padding-left: 8px;
              }
              > .description {
                padding-left: 8px;
                font-weight: lighter;
                font-size: 0.85em;
                padding: 4px 8px;
              }
            }
          }
        }

        > .notes {
          padding: 4px 18px;
          color: var(--primary-color);
          font-size: 0.8em;
          font-weight: lighter;
        }
        > .footer {
          color: var(--primary-color);
          font-size: 1em;
          text-align: center;
          padding: 4px 12px 24px 12px;
          ion-row {
            height: 2.5em;
          }
          ion-col {
            padding: 0;
            align-self: center;
          }
          .old-price {
            color: var(--text-disabled-color);
            font-size: 0.7em;
            text-decoration: line-through;
          }
        }
        .discount-ladder {
          font-size: 0.9em;
          padding-top: 0.5em;
          padding-bottom: 0.5em;
          border-top-style: solid;
          border-top-width: thin;
          border-top-color: var(--separator-color);
          text-align: center;
          vertical-align: middle;
          .header {
            font-size: 1.1em;
            color: var(--primary-color);
          }
          .discount-step {
            ion-col {
              margin-left: 0;
              align-self: center;
              align-items: center;
              --ion-grid-column-padding: 0;
            }
            border-radius: 6%/50%;
            border-width: 0.5px;
            border-style: solid;
            border-color: var(--inactive-color);
            margin-top: 0.5em;
            margin-bottom: 0.5em;
            margin-left: 5%;
            margin-right: 5%;
            padding-top: 0.5em;
            padding-bottom: 0.2em;
            padding-left: 2%;
            padding-right: 5%;
            .discount-quantity {
              text-align: right;
            }
            .discount-price {
              text-align: left;
              ion-text:first-child {
                color: var(--primary-color);
              }
            }
            .discount-value {
              color: var(--primary-color);
            }
            .button {
              margin-left: 2em;
              border-radius: 5px;
              padding: 4px;
              width: 2em;
              height: 2em;
              ion-icon {
                color: var(--primary-color);
                margin: 0;
              }
            }
          }
          .discount-step-match {
            border-color: var(--primary-color);
          }
        }
        .footer {
          .price {
            ion-col:first-child {
              text-align: left;
              color: #5e5e5e;
            }
            ion-col:last-child {
              text-align: right;
            }
          }
          .total {
            ion-col:first-child {
              text-align: left;
              font-weight: bold;
            }
            ion-col:last-child {
              text-align: right;
              font-weight: bold;
              font-size: 1.25rem;
            }
          }
        }
      }
    }
  }
}