@import '../../assets/scss/mixins';

.order-confirmation-page {
  z-index: 20000 !important;
  ion-page{
    background:  var(--background-color-blue);
  }
  ion-content {
    --background:  var(--background-color-blue);
    > div {
      margin: 0 20px;
    }

  .containerShipping__element{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    gap: 40px;
    height: 42px;
    background: #FFFFFF;
    box-shadow: 0px 3px 10px rgba(30, 55, 153, 0.1);
    border-radius: 10px;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
  .containerShipping{
    margin-bottom: 47px;
    margin-top: -23px;
  }
  .containerShipping__title{
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #1E3799;
    margin-bottom: 10px
  }
  .containerShipping__element--modifier{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #1E3799;
  }
    .success-order {
      text-align: center;
      ion-img {
        max-width: 70%;
        margin: 1rem auto 0;
      }
      .message {
        font-weight: bold;
        color: #142242;
        margin: 1rem 0;
        font-size: 1.75rem;
      }
      ion-button {
        width: 70%;
      }
    }
    .summary {
      border-bottom-style: solid;
      border-bottom-width: thin;
      border-bottom-color: #dadada;
      padding-bottom: 1.25rem;
      margin-bottom: 1.25rem;
    }
    .cart-total {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5em;
      color: var(--primary-color);
      font-weight: bold;
      font-size: 1.1rem;
    }
    .delivery-options {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5em;
      color: #373737;
    }
    .address-section {
      .paragraph-2 {
        margin-top: -7px;
        line-height: 18px;
      }
      .office-desc-text {
        display: flex;
        align-items: center;
        gap: 5px;
        margin: 15px 0px 12px 0px;

        & > h5 {
          margin: 0px;
        }
      }
    }
    .phone-section {
      .paragraph-2 {
        margin-top: -7px;
      }
      .tittle-phone-section{
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
        color: var(--color-blue);
        margin: 30px 13px 13px 13px;
      }
      textarea {
        min-height: 6rem;
        background-color: #999999;
        padding: 10px;
        font-size: 15px;    
        background: #FFFFFF;
        border: 1px solid var(--color-blue);
        box-sizing: border-box;
        border-radius: 20px;
      }
      .label-with-editable-link {
        ion-label{
          padding: 10px;
        }
        ion-chip {
          --color: var(--primary-color);
          --background: transparent;
        }
      }
   
      .phone-box {
        align-items: center;
        vertical-align: middle;
        ion-input {
          margin-bottom: -11px;
        }
        .phone-prefix {
          font-family: inherit;
        }
      }
    }
    .comment-section {
      margin: 30px 0px 56px 0px;
      .tittle-phone-section{
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
        color: var(--color-blue);
        margin: 30px 13px 13px 13px;
      }
      textarea {
        min-height: 6rem;
        background-color: #999999;
        padding: 10px;
        font-size: 15px;    
        background: #FFFFFF;
        border: 1px solid var(--color-blue);
        box-sizing: border-box;
        border-radius: 20px;
      }
    }
    .email-section{
      // margin-top:-10px;
      .paragraph-2 {
        margin-top: -7px;
      }
    }
  
    .invalid {
      ion-input {
        border-color: red;
        border-width: 1px;
        border-style: solid;
        margin-top: 10px;
      }
    }
    .container-info-user-order{
      background: #FFFFFF;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      padding: 2px 14px 14px 14px;
      margin-top: 20px;
    }
    .user-contact-info-wrapper {
      background: #FFFFFF;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      padding: 12px 14px 12px 14px;
      margin-top: 20px;
    }
    ion-label {
      font-weight: 600;
    }

    .resume-section {
      .provider-list-div {
        margin-top: 4%;
        margin-top: -17px;
        background: #fff;
        border-radius: 10px;
        .text-title{
          padding: 14px 14px 0px 14px;
          border-bottom: 1px solid var(--color-blue);
        }
        .container-summary{
          margin: 10px;
          padding-top: 4px;
          font-size: 16px;
          color: var(--text-title-gray-6);
          border-bottom: 1px solid #f1f1f1;
          padding-bottom: 10px;
          font-weight:  bold;
          text-transform: uppercase;
        }
        .previous-payment{
          margin: 10px 10px 15px 10px;
        }
        .invalid {
          border-color: var(--secondary-color);
          border-width: 2px;
          border-style: solid;
        }
        div{
        .container-title-provider{
          .provider-summary {
            .provider-header {
              margin: 10px;
              .provider-title {
                display: flex;
                justify-content: space-between;
              }
              .delivery-info {
                margin-top: 5px;
                display: flex;
                justify-content: space-between;
                text-transform: capitalize;
              }
              .delivery-info-free {
                font-size: 14px;
                color: #1E3799;
                font-weight: 700;
              }
            }
            .provider-minimum {
              font-weight: bold;
            }
            .provider-discount {
              padding-top: 2px;
              padding-left: 6px;
              height: 40px;
              background-color: #66F0BC;
              .provider-discount-text {
                color: white;
              }
              .provider-discount-text2 {
                color: #747A78;
              }
            }
  
            .provider-products {
              .container-product {
                .product-info {
                  .product-price {
                    color: black;
                  }
                }
                .product-operations {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-end;
                  flex: 2;
                  .product-operation {
                    border-width: 0;
                    padding: 0;
                    margin-right: 1em;
                    .icon {
                      margin: 0;
                    }
                  }
                }
              }
            }
            .provider-footer {
              display: flex;
              flex-direction: column;
              align-items: center;
              .provider-total {
                background: #fff;
                margin-top: 1em;
                padding: 0.5rem;
                display: flex;
                align-items: center;
                width: 98%;
                .provider-total-info {
                  flex: 3;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  margin-left: 0em;
                  margin-right: 0em;
                  .provider-total-title {
                    font-size: 1em;
                    font-weight: bold;
                  }
                  .provider-total-value {
                    color: var(--primary-color);
                    font-size: 1.5em;
                    font-weight: bold;
                    text-align: right;
                  }
                }
                .provider-button {
                  flex: 2;
                  ion-button {
                    --background: transparent;
                    --color: var(--primary-color);
                    --border-color: var(--primary-color);
                    --border-width: 2px;
                    --border-style: solid;
                  }
                }
              }
              .provider-minimum-message {
                color: var(--secondary-color);
                font-size: 0.8em;
                text-align: center;
                vertical-align: middle;
                margin-bottom: 1em;
                ion-text {
                  font-weight: bold;
                }
              }
            }
          }
      }
    }
      .container-discount{
        margin: 10px 10px 15px 10px;
        .not-total{
          display: inline-block;
        }
        .total-discount-not{
          display: inline-block;
          float: right;
        }
        .title-discount{
          margin-top: 21px;
          padding-top: 4px;
          border-bottom: 1px solid #f1f1f1;
          padding-bottom: 10px;
        }
        .total-discount{
          display: flex;
          justify-content: space-between;
          margin-top: 10px;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          color: var(--normal-tex);
          padding: 1px;
        }
        .name-promotion{
          display: inline-block;
          padding: 2px;
        }
        .discount-promotion{
          display: inline-block;
          float: right;
        }
        .container-promotion{
          display: flex;
          justify-content: space-between;
          margin-top: 10px;
        }
      }
      .container-total{
        margin: 10px;
        display: flex;
        justify-content: space-between;
        font-size: 18px;
        color:var(--primary-color-celeste);
        font-weight: bold;
      }
  
        .provider-delivery {
          text-align: center;
          font-size: 0.8em;
          color: var(--secondary-color);
          padding: 0.5em;
        }
      } 
    }
    .note{
      text-align: center;
      font-size: 12px;
      color: #EB2F06;
    }
    .payment-method{
      margin-top: 30px;
      .select-payment-method{
        padding: 12px;
        display: flex;
        justify-content: space-between;
        background: #FFFFFF;
        border-radius: 10px;
        .icon-arrow{
          display: flex;
          align-items: center;
        }
       h5{
         margin: 0;
       }
      }
      .form-method{
        padding-top: 4px;
        padding-bottom: 10px;
        padding: 0.5em;
        padding-bottom: 0em;
        margin-bottom: 7px;
    }

  }
  }
}
.confirmation-section {
  background:  var(--background-color-blue);
  .btn-primary{
    width: 91%;
    margin: 17px 17px 33px 17px;
  }
}

.alert-class {
  .alert-wrapper {
    min-width: 300px;
    .alert-head {
      .alert-title {
        text-transform: none !important;
        font-size: 20px;
        font-weight: 600;
        color: #333;
        text-align: left;
      }
    }
    .alert-message {
      font-size: 14px;
      line-height: 22px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .order-confirmation-page {
      ion-content {
      .user-information{
        margin: 0 20px; 
        max-width: 1000px;
      }
  }
  .confirmation-section {
    ion-button{
      max-width: 1000px;
      margin-bottom: 20px;
      }
    }
  }
}

.method-selector {
  background-color: #fff;
  border: 1px solid #ccc;
}


.container-processing-order{
  position: fixed;
  margin: 0px !important;
  width: 100%;
  height: 100%;
  .content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--new-dark-blue);
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    color: #FFFFFF;
    }
  }
