.home-fulfillment-categories {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.6rem;
    flex-direction: column;
    margin-top: 0.5rem;
    cursor: pointer;

    & > img {
      width: 5rem;
      height: 5rem;
    }

    & > h5 {
      color: var(--color-blue);
      font-size: 0.9rem;
    }
  }
}
