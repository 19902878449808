ion-modal.modal-action-accept {
  .modal-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100% !important;
    height: 100% !important;
    .ion-page{
      background: var(--background-color-blue);
    }
  }
}
.modal-content-wrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 50px);
  .container-modal-action-accept{
    background: var(--background-color-blue);
    .label-accept-guru{
      font-style: normal;
      font-weight: bold;
      font-size: 1.1em;
      line-height: 21px;
      color: var(--color-blue);
    }
    .container-img-action-accept{
      display: flex;
      justify-content: center;
      .img-props-action-accept{
        width: 125px;
        height: 125px;
      }
    }
    .container-label-action-accept{
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      margin-top: 35px;
      padding: 0 20px;
    }
    .container-btn-modal-action-accept{
      display: flex;
      justify-content: center;
      margin-top: 35px;
    }
    .btn-action-accept{
      margin-top: 100px;
    }
  }
}