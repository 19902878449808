ion-modal.promotion-modal-full-screen {
  .modal-wrapper {
    background: transparent;

    div.ion-page {
      overflow: visible;
      width: 100%;
      height: 100%;
    }
      .content {
        background: rgba($color: #000000, $alpha: 0.6);
        position: relative;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        .header {
          width: 100%;
          text-align: right;
          padding: 10px;
          height: 10%;
          .close-banner{
            font-size: 20px;
            border-radius: 100px;
            padding: 0 5px;
            margin-top: 25px;
            display: inline-block;
            cursor: pointer;
            line-height: 15px;
          }
        }
        .container-img{
          align-self: center;
          width: 85%;
          height: 80%;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }
}

