.home-brand-detail-page {
  .loading-offers {
    padding: 10px;
    .loading {
      margin-top: 20px;
    }
    .spinner-home-brand-detail {
      padding: 10px;
      --color: var(--text-label-color);
    }
  }
  .container-words-slider{
    display: flex;
    .icon-arrow{
      margin-left: 27px;
      margin-top: 4px;
    }
    .words-slider-arrow {
      padding-left: 25px;
      margin-top: 4px;
    }
  }
  .container-category-provider{
    overflow-x: scroll;
    max-height: 80vh;
    ion-button{
      --border-radius: none;
      --background: none;
      --box-shadow: none;
      width: 100%;
      margin-bottom: 32px;
      font-size: 14px;
      font-weight: 400;
      color: var(--primary-color-celeste);
      --background-activated: none;
      --background-focused: none;
      --background-hover: none;
      ion-spinner{
        --color:var(--primary-color-celeste)
      }
    }
    .loading-section {
      --color: var(--text-label-color);
      width: 100%;
      text-align: center;
      padding-top: 2em;
    }
  }
}




@media only screen and (min-width: 1024px) {
  .home-brand-detail-page {
    .container-category-provider {
      max-width: 1000px;
      margin: 0 auto ;
      overflow: auto;
      width: 100%;
    }
  }
}