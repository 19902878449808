.suggested-checkout-modal {
  z-index: 2000 !important;
  --background: var(--background-color-blue);
  .body {
    overflow: auto;
    --background: var(--background-color-blue);
    > div {
      display: flex;
      flex-wrap: wrap;
      .container-card{
        width:45%; 
        margin:2%
      }
    }
    .product-slider {
      border-bottom: 1px solid #f1f1f1;
      box-shadow: none;
      > .header {
        padding: 12px 24px 0 19px;
        width: 100%;
        > span {
          font-weight: bold;
          color: var(--color-blue);
        } 
        .seeMore {
        float: right;
        top: 10px;
        margin-top: 5px;
        margin-right: -12px;
        }
      }
      > .slider-body {
        margin: 1px 10px 15px 10px;
        width: 100%;
         > div {
          display: flex;
          flex-wrap: wrap;
          .container-card {
            width:45%; 
            margin:2%
          }
        }
      }
    }
    .info-section {
      flex-wrap: nowrap;
      align-items: center;
      position: relative;
      top: -56px;
      margin-bottom: -66px;
      padding-left: 34px;
      padding-right: 34px;
      justify-content: center;
      .image {
        display: block;
        margin: 5px;
        width: 7em;
        height: 5em;
        margin-right: 20px;
      }
      .text {
        display: block;
        font-weight: 600;
        .title {
          color: var(--primary-color-celeste);
          font-size: 16px;
        }
        .description {
          margin-top: 0.4em;
          font-size: 12px;
          color: var(--text-title-gray-6);
        }
      }
    }
  .loading-suggested {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    color: var(--text-label-color);
    position: relative;
    width: 100%;
    display: block;
    top: 35%;
      .loading {
        font-size: 14px;
        color: var(--text-label-color);
        margin-bottom: 10px;
      }
      .spinner-mid {
        display: block;
        margin: 0px auto 15px auto;
        --color: var(--text-label-color);
      }
    }
  }
  .confirmation-section {
    background-color: var(--background-color-blue);
    padding: 17px;
    ion-button {
      --background:var(--primary-color-celeste);
      --background-activated: var(--primary-color-celeste);
      --border-radius: 50px;
        font-size: 14px;
        font-weight: 700;
        color: #FFF;
    }
  }
}

@media only screen and (min-width: 1024px) {  
  .suggested-checkout-modal {
    .body {
      max-width: 1000px;
      margin: 0 auto ;
      overflow: auto;
      width: 100%;
    }
    .confirmation-section {
      ion-button{
        max-width: 1000px;
        margin-bottom: 20px;
      }
    }
  }
}

.alertToast{
  text-align: center;
}