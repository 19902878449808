.product-slider {
  border-bottom: 1px solid #f1f1f1;

  > .header {
    padding: 12px 24px 0 24px;
    display: flex;
    justify-content: space-between;
    > span {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      color: var(--color-blue);
    } 
    .seeMore{
      margin-right: 30px;
      .button-more{
        display: flex;
      }
    }
  }
  > .body {
    margin-top: 8px;
    margin-bottom: 15px;
     > div{
      display: flex;
      flex-wrap: wrap;
      .contairner-card{
        width:29%; 
        margin:2%
      }
    }
  }
  > .footer {
    display: flex;
    justify-content: flex-end;
    color: var(--primary-color);
    > div.button-more {
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
      padding: 16px;
    }
  }
}
