.home-fulfillment-content {
  margin: 1rem auto 1.5rem auto;
  max-width: 60rem;

  & > ion-segment {
    --background: var(--background-color-blue);
    max-width: 1000px;
    margin: 0 auto;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);

    ion-segment-button {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      text-transform: none;
      color: var(--text-title-gray-3);
    }
    .isChecked {
      color: var(--color-blue);
      font-weight: bold;
    }
  }
}
