.page-category-provider {
  .container-words-slider{
    display: flex;
    .icon-arrow{
      margin-left: 27px;
      margin-top: 4px;
    }
  }
  .container-category-provider{
    overflow-x: auto;
    max-height: 80vh;
    ion-button{
      --border-radius: none;
      --background: none;
      --box-shadow: none;
      width: 100%;
      margin-bottom: 32px;
      font-size: 14px;
      font-weight: 400;
      color: var(--primary-color-celeste);
      --background-activated: none;
      --background-focused: none;
      --background-hover: none;
      ion-spinner{
        --color:var(--primary-color-celeste)
      }
    }
    .loading-section {
      --color: var(--text-label-color);
      width: 100%;
      text-align: center;
      padding-top: 2em;
    }
  }
  div.product-list-1 {
    margin-top: 4%;
    > .product-card {
      width: 29%;
      float: left;
      margin-left: 3%;
      margin-bottom: 4%;
      background: white;
    }
  }
}

.container-filter{
  --background:#fff;
  --color:var(--text-title-gray-6);
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  ion-select{
    --placeholder-opacity:var(--emphasis-color);
    &:part(.select-icon){
      opacity:1!important;
    }
  }
}

.no-product{
  margin: 10px;
  margin-top: 23px;
}

@media only screen and (min-width: 1024px) {  
  .page-category-provider {
    .container-category-provider{
      max-width: 1000px;
      margin: 0 auto ;
      overflow: auto;
      width: 100%;
    }
  }
}
