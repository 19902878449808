ion-slides {
  margin-left: 20px;
  .ion-slide-word {
    width: auto !important;
    ion-button {
      max-height: 26px;
      display: flex;
      font-size: 14px;
      margin: 0;
      text-transform: none;
      --ripple-color: transparent;
    }
  }
}

.container-slider {
  margin-bottom: 15px;
  overflow-x: auto;
}

.selected {
  font-weight: 600;
  color: var(--white);
  background-color: var(--color-blue);
  border-radius: 5px;
}

.selected .tab-offer {
  background-color: #F7432B;
}

.not-selected {
  font-size: 1.1em;
  font-weight: 600;
  color: var(--text-title-gray-6);
}

.isFirst {
  margin-left: 20px;
}

.last-word {
  margin-right: 20px;
}